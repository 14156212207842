import { Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import SingleHeader from "../components/SingleHeader"
import "../styles/global/notfound.css"

export default function NotFound() {
  return (
    <Layout>
      <SingleHeader pageTitle="404 Page" pageName="Not Found" />
      <section class="notfound-section">
        {/* <!-- Container --> */}
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <div class="notfound_wrap">
                <img src="images/about.png" alt="" />
                <h2> Oops... Page Not Found!</h2>
                <p> Sorry the page could not be found here</p>
                <Link to="/" class="more-link">
                  Back to home
                </Link>
              </div>
            </div>
            {/* <!--/ col-sm-12  --> */}
          </div>
          {/* <!--/ row - --> */}
        </div>
        {/* <!--/ Container - --> */}
      </section>
    </Layout>
  )
}
